import React from "react"
import { Helmet } from 'react-helmet'
import '../components/global.css'

export default function Home() {
    return (
	<>
	    <Helmet>
		<link rel="preconnect" href="https://fonts.gstatic.com" />
		<link href="https://fonts.googleapis.com/css2?family=Scheherazade&display=swap" rel="stylesheet" />
	    </Helmet>
	    <>
 		<div style={{ backgroundColor: 'black' , display: 'grid' , placeItems: 'center' , height: '100vh' }}>
		    <div style={{ color: 'white' }}>
                        <center>
                        <p>There are currently <span style={{ color: 'red' }}>2</span> living representatives alive.<br />
                        One is essentially married, so claiming the name is tenuous.<br />
                        One is unhealthy and has not produced any heirs to the surname.<br />
                        Unless successful breeding is accomplished soon extinction is possible.<br />
                        There are problems with the genes. So, it might be for the best.</p>
                        </center>
		    </div>
	    	</div>
	    </>
	</>
    )
}

